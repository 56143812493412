import request from '../utils/request'
import config from './config'

/**
 * 志愿者管理系统
 */

// 添加志愿者
export function addVolunteer(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerManage/addVolunteer`,
		method: 'post',
		data,
	})
}

// 导出模板下载
export function downloadVolunteerManage(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerManage/downloadVolunteerManage`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 导出志愿者excel表格
export function exportVolunteerMangeExcel(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerManage/exportVolunteerMangeExcel`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 获取用户列表
export function getVolunteerManageList(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerManage/getVolunteerManageList`,
		method: 'post',
		data,
	})
}

// 返回新增和编辑团队管理的所需列表
export function groupNameList(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerManage/groupNameList`,
		method: 'post',
		data,
	})
}

// POST
// /volunteerManage/importVolunteerManage
// 志愿者数据导入

// 上传附件
export function uploadAttachment(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerManage/uploadAttachment`,
		method: 'post',
		data,
	})
}

// 上传证件照
export function uploadImg(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerManage/uploadImg`,
		method: 'post',
		data,
	})
}

// 删除志愿者和积分相关数据
export function deleteVolunteer(params) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerManage/deleteVolunteer`,
		method: 'get',
		params,
	})
}

// 更新志愿者
export function updateVolunteer(data) {
	return request({
		url: `${config.CIVIL_HOST}//volunteerManage/updateVolunteer`,
		method: 'post',
		data,
	})
}